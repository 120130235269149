import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Polygon ,Marker,Polyline} from "@react-google-maps/api";
const mapContainerStyle = {
  height: "500px",
  width: "100%", //"900px"
};

const center = { lat: 19.5721573, lng: -99.2726426 };

const MapaDetalle = ({ tamanio, libreriaG, listSeccion, zoom ,coordPerfiles,listCentro}) => {
  const [auxMarcador, setAuxMarcador] = useState(center);
  const [auxZoom] = useState(zoom);  
  
  useEffect(() => {
    setAuxMarcador(listSeccion[0].coordenada[0]);
  }, []);

  const onLoadPolygono = (marker) => {
    //console.log('Poligono: ', marker)
  };

  const optionSector =(color)=> {  
    
    let auxCol="white"
    //console.log(auxCol);  
    const auxiliar={  
    fillColor:auxCol ,
    fillOpacity: 0.2,
    strokeColor: auxCol,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const optionCuadrante = (color) => {
    let auxCol = color;
    const auxiliar = {
      fillColor: auxCol,
      fillOpacity: 0.6,
      strokeColor: auxCol,
      strokeOpacity: 0.2,
      strokeWeight: 1,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    };
    return auxiliar;
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBCY3rXFXjsPGx_X87eXMkgQklkrtfYmCQ"
      language="spanish" libraries={libreriaG}
    >
      <GoogleMap
        id="mapa-monit-alerta" center={auxMarcador} zoom={auxZoom}
        mapContainerStyle={tamanio ? tamanio : mapContainerStyle}
      >                
        {listSeccion !== null && listSeccion.length > 0 //lista de poligonos de Seccion
          ? listSeccion.map((auxSecc, index) => {              
              return (
                <Polygon
                  onLoad={onLoadPolygono}
                  paths={auxSecc.coordenada}
                  options={optionCuadrante(auxSecc.color)}
                  key={index}
                />
              );
            })
          : null}
        { listCentro.length!==0?
          listCentro.map((seccion,index)=>{
            return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={seccion.nombre}   key={index}
              position={ seccion.coordenada}  
              icon= {"marcador_secc.svg"}                   
            />)
          })
        :null }
        { listSeccion.length!==0 ?           //lista de polylineas de sector 
          listSeccion.map((grupo,index)=>{       
            return(
            <Polyline onLoad={onLoadPolygono} path={grupo.coordenada} 
              options={optionSector()} key={index}
            />)
          })                    
          :null  
        } 
         {coordPerfiles!==0 ?
            coordPerfiles.map((perfil,index)=>{
              return(
                <Marker style={{width:50+'px',height:50+'px'}}
                  title={perfil.perfil}   key={index}
                  position={ perfil.coordenada}  
                  icon= {"marcador.svg"}                   
                />)
            })
          :null}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapaDetalle;
