import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { TextField,FormControl,  
    Box,MenuItem,InputLabel,InputAdornment,IconButton,Input,
    FormLabel,FormControlLabel,RadioGroup,Radio} from '@material-ui/core' 
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrar';
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify'; 
import SelectList from './SelectList';
import SelectEdo from './SelectEdo';
import { obtenDispo } from '../funciones/IdDispositivo';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
  
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NuevoUsuario = ({modalAbierto,setModalAbierto,titulo ,listEstruc,recargar,  }) => {
    let history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();  
    const auxDispV =obtenDispo()
    //const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const usuTpEstr= localStorage.getItem('usuTpEstr') ? parseInt( localStorage.getItem('usuTpEstr') ):0 ;     
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
    const[nvoRegis,setNvoRegis]=useState({Nombre:'', Email:'', Pass:'', Tel:'', TipoUsu:'', usuNick:'', 
                                            muni:"", Titular:"1", Superior:"", tipoEstruc:'',
                                            detalle:"", cargo:"", edo:null, organizacion:'', Seccion:null,
                                            region:'', observ:'',distrito:'',organismo:''
                                        });  
    const[visiblePwd,setVisiblePwd]= useState(false)                                                                                           
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] =  useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);    
    const [listMuni, setListMuni] = useState([]);
    const[listSuperior,setListSuperior]= useState([]);    
    const[listOrg,setListOrg]= useState([]);   
    const[listOrganiza,setListOrganiza]= useState([]);   
    const[listSecc, setListSecc] = useState([])      
    const[listRegion, setListRegion] = useState([])      
    const[listDto, setListDto] = useState([])      
    const{Nombre, Email, Pass, Tel, TipoUsu, usuNick, muni, Titular, Superior, tipoEstruc,  
        cargo, detalle, edo, organizacion, Seccion, region, observ, distrito, organismo}=nvoRegis
    
    useEffect(() => {
        getListOrg()
        getListRegion()

        return () => {
            source.cancel();
        }
    }, [])
        
    useEffect(() => {      
        
        if (tipoEstruc) {
            getListSuperior(tipoEstruc)
        }
    }, [tipoEstruc])    

    useEffect(()=>{
        if (edo) {
            getListMuni(edo)  
            if (tipoEstruc===4||tipoEstruc===5) {
                getListDistrito(edo)    
            }            
        }
    },[edo])

    useEffect(() => {
        if (muni) {
            getListSecc(muni)
            
            if (tipoEstruc===9) {
                getListOrganiza(muni)
            }            
        }
            
    }, [muni])

    function getListDistrito(auxEdo)  {            
        const url= `lugar-distrito-estado`;       
        const filtro= listEstruc.filter((estruc)=>estruc.Id===tipoEstruc)       
        console.log(filtro[0]);               
        const data = qs.stringify({                
            usuario:usuID,              
            estado:auxEdo,      
            tipo:filtro[0].Origen==="DTOFED"?"F":"L"  ,                                        
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {   setListDto(aux)
            }  
            else{
                setListDto([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getListMuni(auxEdo)  {            
        const url= `lugar-municipio-tipo`;                            
        const data = qs.stringify({                
            usuario:usuID,              
            estado:auxEdo,    
            tipo: usuTpEstr,                                            
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {   setListMuni(aux)
            }  
            else{
                setListMuni([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getListSuperior(tipo)  {            
        const url= `usuario/v2/estructura/superior`;                            
        const data = qs.stringify({                
            usuario:usuID,           
            tipo:tipo,               
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {                                   
                let filtro=[]
                aux.forEach(element => {
                    if (element.Id>0 ) {
                        filtro.push(element)
                    }
                });
                setListSuperior(filtro)
            }     
            else{
                setListSuperior([])
            }                                                                 
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getListOrg()  {            
        const url= `usuario/v2/estructura-organismo-list`;                            
        const data = qs.stringify({                
            usuario:usuID,                                                                        
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {   setListOrg(aux)
            }  
            else{
                setListOrg([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const getListOrganiza = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "organizacion/v2/municipio-list";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id !== -1) {                
                setListOrganiza(auxiliar)
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    const getListSecc = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuID, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                setListSecc(auxiliar)
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    const getListRegion = () => {
        const data = qs.stringify({
            usuario: usuID, idDispositivo: auxDispV,
        });
        const url = "usuario/v2/region-list";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {                
                setListRegion(auxiliar)
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    function llamadaNvoRegis( )  {             
        const url=`usuario/v2/estructura-nuevo`;                                                                        
        const data = qs.stringify({                                                                   
            usuario:usuID,
            tipo:tipoEstruc,
           // tipoUsr:TipoUsu,
            superior:Superior.Id??0,
            titular:Titular,                  
            usrNom:usuNick,
            usrPass:Pass,
            nombre:Nombre,            
            cargo:cargo,
            email:Email,
            telefono:Tel,          
            organismo:organismo,                                                                                                                    
            detalle:onChangeDet(),//detalle,
            observacion:observ,
            idDispositivo:auxDispV
        });
 
        function respuesta(aux) {                                                                     
            if (aux.length!==0) 
            {                            
                recargar()
                setBtnDeshabilitado(true)                                             
                const mensaje="Se registro correctamente el Nuevo Usuario"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                setAviso(true)     
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-usuario${1}`
                })
                setModalAbierto(false)
            }                                            
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);      
    };

    const onChangeDet=()=>{
        let aux=""
        switch (tipoEstruc) {
            case 1:
                aux=`${edo}`
                break;
            case 2:
                aux=`${region}`
                break;
            case 3:
                aux=`${edo}`
                break;
            case 4:
                aux=`${distrito}`
                break;
            case 5:
                aux=`${distrito}`
                break;
            case 6:
                aux=`${muni}`
                break;
            case 7:
                aux=`${Seccion}`
                break;
            case 8:
                aux=`${Seccion}`
                break;
            case 9:
                aux=`${organizacion}`
                break;
            case 10:
                aux=`${muni}`
                break;
            case 11:
                aux=`${region}`
                break;
            case 12:
                aux=`${muni}`
                break;
            default:
                break;
        }
        return aux
    }

    const onChange = e =>
	{   e.preventDefault()
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis,  
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};         

    const onChangeNumTel = e =>
	{   e.preventDefault()
        let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		    

	};    

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoRegis({ ...nvoRegis, [e.target.name] : e.target.value  })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
  
    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {   respuesta=true    
            }
            else
            {   mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }
 
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {   respuesta=true                    
            }
            else
            {   mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
    
    function validaTipo(arr,tipo,cadena) {
        let valida=true
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if (tipoEstruc === element && verificaNulos(tipo,cadena)) 
            {
                valida=false
            }
        }
      
        return valida
    }
 
    function guardarDatos(e)
    {   e.preventDefault()        
 
        if (//!verificaNulos(TipoUsu,'Permiso') && 
            !verificaNulos(tipoEstruc,"Estructura") 
            && validaTipo([1,3,4,5,6,7,8,9,10,12],edo,'Estado')
            && validaTipo([2],region,'Circunscripción')
            && validaTipo([6,7,8,9,10,12],muni,'Alcaldía')  
            && validaTipo([4,5],distrito,'Distrito')
            && validaTipo([7,8],Seccion,'Sección')
            && validaTipo([9],organizacion,'Organización')            
            &&  !verificaNulos(organismo,'Organismo') 
            && validaVariable(cargo,'Descripción de Usuario',5)
            && validaVariable(Nombre,'Nombre',7) && validaVariable(usuNick,'Usuario',4)   
            && valContra() && validaVariable(Tel,'Teléfono',10)
        )
        {   setMensaje('');                
            setAviso(false);                 
            llamadaNvoRegis()              
        }                                  
    } 

    const guardarSupr=(e)=>{            
        if (e!== null && e!== undefined) 
        {   setNvoRegis({...nvoRegis, Superior : e  })      
            setMensaje('');                
            setAviso(false); 
        }
        else
        {   setNvoRegis({...nvoRegis, Superior : "" })                   
        }        
    }

    const guardarTipoSelect=(e)=>{  
        e.preventDefault()  
        if (e!== null && e!== undefined) 
        { 
            setNvoRegis({...nvoRegis, TipoUsu :e.target.value, Superior : "" })      
        }else
        {   setNvoRegis({...nvoRegis, TipoUsu : "", Superior : "" })           
        }        
    }

    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
    };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();        
    };

    const filtroSlct=(e)=>{
        let arr=[]
        switch (e.target.name) {        
            case 'tipoEstruc':
                arr={...nvoRegis,tipoEstruc:e.target.value,
                    muni:"", region:'',edo:'',Seccion:'',Superior:'',distrito:"",organizacion:"" }
                    setListMuni([])
                    setListDto([])
                    setListSecc([])
                break;
            case 'edo':
                arr={...nvoRegis,edo:e.target.value,
                    muni:"", Seccion:'',distrito:"" ,organizacion:""}
                    setListSecc([])
                break
            case 'muni':
                arr={...nvoRegis,muni:e.target.value, Seccion:'',organizacion:""}
                break
            default:
                arr= {...nvoRegis,[e.target.name]:e.target.value}
                break;
        }
        setMensaje('');                
        setAviso(false);   
        setNvoRegis(arr)
    }

    const onChangeRad=(e)=>{
        e.preventDefault()
        setNvoRegis({...nvoRegis,[e.target.name] : e.target.value,  Superior : ""  })            
    }

    const onChangeSlct=(e)=>{      
        e.preventDefault()      
        if (e!== null && e!== undefined) 
        {   filtroSlct(e) 
        }
        else
        {   setNvoRegis({...nvoRegis,[e.target.name]:""})          
        }        
    }
    
    const slctRegion =                        
    <SelectList lista={listRegion} value={region} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Circunscripción"} nameEtq={"region"}
        estilo={{minWidth:'14rem'}} 
    />  

  /*   const slctTipo =  listTipoUsu.length > 0 ?                       
    <SelectList lista={listTipoUsu} value={TipoUsu} onChange={guardarTipoSelect} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Permiso"} nameEtq={"TipoUsu"}
        estilo={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:.7+'rem'}} 
    />            
    :null  */ 

    const slctTipoEstrc =  listEstruc.length > 0 ?                       
    <SelectList lista={listEstruc} value={tipoEstruc} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Alias"} etiq={"Estructura"} nameEtq={"tipoEstruc"}
        estilo={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} 
    />            
    :null  

    const slctDto =                        
    <SelectList lista={listDto} value={distrito} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Num"} etiq={"Distrito"} nameEtq={"distrito"}
        estilo={{minWidth:7+'rem',marginLeft:"1rem"}} 
    />      

    const slctMuni =                        
    <SelectList lista={listMuni} value={muni} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Alcaldía"} nameEtq={"muni"}
        estilo={{minWidth:18+'rem'}} 
    />            
   
    const slctOrg =                        
    <SelectList lista={listOrg} value={organismo} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Descr"} etiq={"Organismo"} nameEtq={"organismo"}
        estilo={{minWidth:15+'rem'}} 
    />  

    const slctSecc =                        
    <SelectList lista={listSecc} value={Seccion} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Numero"} etiq={"Sección"} nameEtq={"Seccion"}
        estilo={{minWidth:'8.5rem',marginLeft:".5rem"}} 
    />  

    const slctOrganizacion =                        
    <SelectList lista={listOrganiza} value={organizacion} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Organización"} nameEtq={"organizacion"}
        estilo={{minWidth:'15rem'}} 
    />  

    const DatosGenerales=(
        <div>
            <Box display="flex" flexDirection={"column"}>
                
                <Box>
                    {/* slctTipo */}
                    {slctTipoEstrc}
                </Box>
                {tipoEstruc===2 || tipoEstruc===11? <Box> {slctRegion} </Box>: null}
                {tipoEstruc && tipoEstruc!==2 && ( (tipoEstruc>=1&& tipoEstruc<=10)|| tipoEstruc === 12 ) ?   
                    <Box >
                        <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />                    
                        {(tipoEstruc=== 4 || tipoEstruc === 5) &&  listDto.length !== 0 ? slctDto :null }
                    </Box> 
                :null}
                <Box mt={1}>
                    {((tipoEstruc>= 6 && tipoEstruc<= 10 ) || tipoEstruc === 12) && listMuni.length !== 0 ? slctMuni :null }
                    {(tipoEstruc=== 8 || tipoEstruc === 7) &&  listSecc.length !== 0 ? slctSecc :null }                        
                </Box>           
                <Box>
                {tipoEstruc=== 9 &&  listOrganiza.length !== 0 ? slctOrganizacion :null }
                </Box>
                <Box display="flex" mt={1} >                        
                    <FormControl component="fieldset" style={{marginTop:".5rem" }}>                              
                        <RadioGroup  row aria-label="position"  defaultValue="top"
                            name="Titular" value={Titular} onChange={onChangeRad}
                        >   
                            <FormControlLabel value={"1"}  label={"Titular"}     
                                control={<Radio color="primary" />} />                             
                            <FormControlLabel value={"0"}  label={"Encargado"}             
                                control={<Radio color="primary" />} />
                                                                                        
                        </RadioGroup>                     
                    </FormControl>                                                  
                {tipoEstruc!==1 &&  listSuperior.length !== 0 ? 
                    <div> 
                        <Autocomplete
                            id="sel-superior" size='small' style={{ width:'19rem',marginTop:".5rem" }}
                            onChange={(e,option)=>guardarSupr(option)}  value={Superior} name="Superior"             
                            getOptionLabel={(option) => option.Cargo?`${option.Cargo} - ${option.Nom}`:""} 
                            noOptionsText={"Sin coincidencias"} options={listSuperior}
                            renderInput={(params) => (
                                <TextField {...params} size='small' label="Superior" variant="outlined" />
                            )} 
                        />
                    </div>
                    :<TextField id="input-super" disabled
                    style={{paddingBottom:.5+'rem',width:10+'rem',marginTop:".5rem" }}
                    label="Superior" value={"Sin Superior"} name='' size="small" autoComplete='off'/>}
                </Box>            
                <Box> {slctOrg} </Box>
            </Box>
            
            <TextField required id="descripcion-texto1" onChange={onChange} 
                style={{paddingBottom:.5+'rem',width:25+'rem',marginTop:".5rem" }}
                label="Descripción de Usuario" value={cargo} name='cargo'size="small" autoComplete='off' // defaultValue="Nombre"                
            />
           <br/>
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:25+'rem' }}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
            />
            <br/>
            <TextField  id="Usu-input2" onChange={onChangeCorreo} autoComplete='off'
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                style={{width:12.5+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
            />                 
            <FormControl  size="small" style={{width:12.5+'rem'}} >
                <InputLabel htmlFor="input-pwd-usu" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-pwd-usu"
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='off' name="Pass"
                    onChange={onChange } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} 
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxLength: 10 }} autoComplete='off'               
               style={{paddingRight:1+'rem',width:8.5+'rem'}}
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} autoComplete='off'
                label="Email"  value={Email} name='Email' size="small"                
                style={{paddingBottom:1+'rem',width:16.5+'rem'}}
            />  
            <br/>
            <TextField  id="observ-input" onChange={onChangeCorreo} autoComplete='off'
                label="Observaciones"  value={observ} name='observ' size="small" inputProps= {{ maxLength: 50 }}                
                fullWidth multiline maxRows={3} style={{width:"27rem"}} variant='outlined'
            />             
        </div> 
    )       
 
    return (            
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}        
    >
        {DatosGenerales}
    </Modal> 
    )
}

export default NuevoUsuario
