import React from 'react'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Box, Paper,Card, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';        
 
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },     
    estiloCard:{
        width: '28.5rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },        
    tablaContainer: {
        width: '28.5rem',
        maxHeight: window.innerHeight-170,
        minHeight: 280,
        //minWidth:'100%',
        }, 
    tableRow: {
        "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
        }
    },
    tableCell: {
        "$selected &": {
        color: "white"
        }
    },
    hover: {},
    selected: {}        
});
const TablaMunxReg = ({seleccionado,setMuniSelecc, }) => {
    const classes = useStyles();
    const [listMuni,setListMuni]=React.useState([])
    const [totales,setTotales]=React.useState({ totalVal:0,totalPend:0,totalRech:0,totalSecc:0})
    const { totalVal,totalPend,totalRech,totalSecc}=totales
   // const [auxSeleccionado,setAuxSeleccionado] = React.useState([]);
    const [idSelec,setIdSelec] = React.useState(-1)

    React.useEffect(()=>{
       console.log(seleccionado);
       if (seleccionado[0].Mnunicipios.length!==0) {
            
            let   totVal=0,totPend=0,totRech=0,totSecc=0, arrMun=[]     
            seleccionado[0].Mnunicipios.forEach(element => {
                let auxPend=0
                let auxValid=0
                let auxRech=0
                element.Status.forEach((std)=>{            
         
                    let auxiliar=true    
 
                    if (auxiliar&& std.Nom==="Pendiente") {
                        auxiliar=false
 
                        auxPend=auxPend+std.Total
                    }
                    if (auxiliar&& std.Nom==="Valido") {
                        auxiliar=false
                        auxValid=auxValid+std.Total
                    }
                    if (auxiliar&& std.Nom==="Rechazado") {
                        auxiliar=false
                        auxRech=auxRech+std.Total
                    }  
                 
                })
                totVal=totVal+auxValid
                totPend=totPend+auxPend
                totRech=totRech+auxRech
                totSecc=totSecc+element.Secciones.length
                arrMun.push({MuniId:element.MunId,nombre:element.MunNom,pendiente:auxPend,
                    valid:auxValid,rech:auxRech,secciones:element.Secciones.length}) 
              });    
              console.log(arrMun);
              setListMuni(arrMun)  
            setTotales({ totalVal:totVal,totalPend:totPend,totalRech:totRech,totalSecc:totSecc})
       }

 
    },[seleccionado])

    const RenglonNum =(valorId,regis)=>{        
        //console.log(regis);     
        setIdSelec(valorId);        
      //  setMuniSelecc(regis);
    }

    const CeldaTitulo=(contenido,alinear,estilos)=>{
        return(
        <TableCell padding='none' align={alinear} style={estilos} >
            {contenido} 
        </TableCell> 
        )
    }
    const CeldaTitulo2=(contenido,total,alinear,estilos)=>{
        return(
        <TableCell padding='none' align={alinear} style={estilos} >
            {contenido} {numeroFormato(total)}
        </TableCell> 
        )
    }

    const CeldaContenido=(contenido,alinear,estilos)=>{
        return(
        <TableCell className={classes.tableCell} padding='none' align={alinear} 
            style={estilos} size="small"
        >
            {contenido} 
        </TableCell> 
        )
    }
 
    const numeroFormato=(datos)=>{
        return(
            <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
        )
    }
    
    function TablaMunicipios(auxDatos,total) {        
        const estiloGeneral={backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white'
                            , borderBottom:"1px solid #0F82EE"}                
        return(        
        <TableContainer className={classes.tablaContainer} id="tamTablaMunicipios" 
            style={{height:8+'rem'}}
        >
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                <TableRow>  
                    {CeldaTitulo(total+" Municipios","center",estiloGeneral)}                                                
                    {CeldaTitulo2("Valido",totalVal,"center",estiloGeneral)}
                    {CeldaTitulo2("Pendiente",totalPend,"center",estiloGeneral)}  
                    {CeldaTitulo2("Rechazado",totalRech,"center",estiloGeneral)}                      
                    {CeldaTitulo( "Secciones "+totalSecc,"center",estiloGeneral)}    
                </TableRow>                        
            </TableHead>  
            <TableBody>                                  
            {auxDatos.map((municipio,index) => {                                                    
                return (                  
                <TableRow className={classes.tableRow} selected={ idSelec === municipio.MuniId }  
                    classes={{ hover: classes.hover, selected: classes.selected }} 
                    key={index} onClick={ () => RenglonNum(municipio.MuniId,municipio) }  
                >                                                 
                    {CeldaContenido(municipio.nombre.charAt(0)+ municipio.nombre.slice(1).toLowerCase(),"left",{paddingLeft:0.5+'rem'})}                                               
                    {CeldaContenido(numeroFormato(municipio.valid),"center",{paddingLeft:0.5+'rem'})}                                               
                    {CeldaContenido(numeroFormato(municipio.pendiente),"center",{paddingLeft:0.5+'rem'})}                                                                                                                                                     
                    {CeldaContenido(numeroFormato(municipio.rech),"center",{paddingLeft:0.5+'rem'})}                                                                                                                                                     
                    {CeldaContenido(numeroFormato(municipio.secciones),"center",{paddingLeft:0.5+'rem'})}                                                                                                                                                     
                </TableRow>              
                );                        
            })}                                                
            </TableBody>
        </Table>
        </TableContainer>  
        ); 
    }

    const tabla = listMuni.length !== 0 ? TablaMunicipios(listMuni,listMuni.length):null
    return (
    <Box mt={1}  >
        <Card className={classes.estiloCard} >
       <Paper className={classes.table}>
        {tabla}
       </Paper>
       </Card>
    </Box>
    )
}

export default TablaMunxReg