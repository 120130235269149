import React, { useState } from 'react';
import { Box, Typography, Card, Button, CircularProgress, Grid, DialogContent, DialogActions, IconButton, Tooltip } from '@material-ui/core';
import ModalInformativo from '../generales/ModalInformativoStd2';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { green, red } from '@material-ui/core/colors';
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        minWidth: 35 + 'rem'
    },
    root2: {
        alignItems: 'center',
        margin: 'auto',
        padding: 0,
        minWidth: 35 + 'rem'
    },
    cardImagen: {
        width: '95%',
        height: '90%',
        marginRight: theme.spacing(2),
        position: 'relative',
        alignContent: 'center'
    },
    noDisponible: {
        fontSize: '1rem',
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: '3rem',
        paddingBottom: '3rem',
        marginRight: theme.spacing(2),
    },
    cardDatos: {
        marginBottom: .5 + 'rem',
        position: 'relative'
    },
    imgContent: {
        padding: 0,
        marginRight: theme.spacing(2),
        overflow: 'hidden'
    },
    imagen: {
        width: '100%',
        height: '23rem'
    },
    wrapper: {
        marginTop: 0,
        paddingTop: 0,
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonGuardar:{color: green[500],},
    buttonRechazar:{color: red[500],},
    margin: {
        margin: theme.spacing(2),
    },
}));

export default function InfoPromo({ seleccionado, setModalAbierto, modalAbierto, titulo ,listPerfil}) {
    const classes = useStyles();
    let history = useHistory();
    //console.log(seleccionado);
    const usuId = localStorage.getItem('UsuId');
    const source = axios.CancelToken.source();
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'      
    const [btnOpcion, setBtnOpcion] = useState(0);
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");    
    const [espera, setEspera] = useState(false);
    const [sinImg, setSinImg] = useState(false);
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");    
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);    

    let fecha = moment.utc(seleccionado.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? ""
        : moment.utc(seleccionado.Fecha).format('DD/MM/YYYY HH:mm')

    React.useEffect(() => {
        //console.log(listPerfil,seleccionado);
        if (seleccionado?.Imagen !== undefined && seleccionado.Imagen !== ""
            && seleccionado.Imagen.length > 0) {
            setSinImg(true)
        }

    }, [])

    const cambiarStatus = (e, auxValor) => {
        e.preventDefault()
        //console.log(auxValor);
        llamadaNvoStd(seleccionado.Id, auxValor)
        setBtnOpcion(auxValor)
    }

    function tipoEdoProspecto(auxColorStd, etiqueta) {
        return(
        <Card style={{
            color: auxColorStd === "#EBF1F8" ? "black" : 'white', fontWeight: "bold",
            backgroundColor: auxColorStd, textAlign: 'center'
        }}>
            {etiqueta}
        </Card>
        )
        
    }

    const llamadaNvoStd=(auxPerfil, auxStd)=>{                     
                
        const data = qs.stringify({
            Usuario: usuId,
            Perfil: auxPerfil,
            Status: auxStd,
            idDispositivo:auxDispV
        });
        function respuesta(aux){
            if (aux.length !== 0) {
                //setNvoRegistro(aux[0]);               
                // setBtnDeshabilitado(true)                                  
                const mensaje = "Se actualizo el Estatus"
                //setBtnDeshabilitado(true)
                setTipoAdvertencia("success")
                setMensaje(mensaje);
                setAviso(true)
                let auxStdColor="",StdNom=""
                switch (auxStd) {
                    case 1:
                        StdNom="Pendiente"
                        auxStdColor="#FBBE06"
                        break;
                    case 2:
                        StdNom="Valido"
                        auxStdColor="#19A11E"
                        break;
                    case 3:
                        StdNom="Rechazado"
                        auxStdColor="#E91E63"
                        break;
                    default:
                        break;
                }
                let auxlistArray=listPerfil 
                const pos =auxlistArray.findIndex((pefl)=>pefl.Id===seleccionado.Id)                 
                const arrEdit={...seleccionado,StatusId:auxStd,StatusColor:auxStdColor,StatusNom:StdNom, }
                auxlistArray.splice(pos, 1,arrEdit )  
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `info-perfil${auxPerfil}`
                })
                setModalAbierto(false)
            }                      
        }
        const url = "perfil-validar";
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    }        

    const descargar3=(url)=>{
        //console.log("antes de crear el link");
        let source =url
        let a = document.createElement('a');
        //a.download = true;
        //a.target = '_blank';
        a.href= source;  
        a.download = "YoSoy"+seleccionado.Nombre+".png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);      
        //window.open(source)
    } 

    const cardPerfil3 = (
        <Grid container className={sinImg ? classes.root : classes.root2} justifyContent="center" >
            <Grid item xs={sinImg ? 7 : 6} >
                {  sinImg ? 
                <Tooltip title={`Descargar Imagen`}>
                    <IconButton onClick={()=>descargar3(seleccionado.Imagen)}>
                        <CloudDownloadIcon/>
                    </IconButton>
                </Tooltip>
                    
                :null  }
              
            {   sinImg ?
                <DialogContent className={classes.imgContent}>
                    <img src={seleccionado.Imagen} className={classes.imagen} alt="foto" />
                </DialogContent>
                :
                <Card className={classes.noDisponible} >
                    <Typography> Sin foto disponible </Typography>
                </Card>
            }
            </Grid>
            <Grid item xs={sinImg ? 5 : 6} style={{ alignContent: 'center', textAlign: 'center' }}>
                <Card>
                    {   seleccionado.Nombre ?
                        <DialogContent className={classes.margin} style={{ padding: 0 }}>
                            <Typography variant="subtitle2" style={{ fontWeight: 650 }}>
                                {seleccionado.Nombre}
                            </Typography>
                            <Typography color="primary" variant="subtitle2" gutterBottom>
                                {fecha}
                            </Typography>
                        </DialogContent>
                        : null
                    }
                </Card>
                <Card style={{ marginTop: '1rem' }}>
                    {   seleccionado.StatusId === 1 ?
                        <>
                        <DialogContent >
                            {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
                            <Typography className={classes.margin} variant="subtitle2"> Seleccione una opción </Typography>
                            {sinImg ?
                                <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                                    <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  } //disabled={espera ? true : false}
                                        variant="outlined" style={{  marginRight: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                                        className={classes.buttonRechazar}
                                    >
                                        Rechazar
                                    </Button>
                                    <span className={classes.wrapper}>
                                    <Button onClick={e => cambiarStatus(e, 2)} variant="outlined" disabled={btnDeshabilitado? true : espera  }//disabled={espera ? true : false}
                                        style={{   marginLeft: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}                                        
                                        className={classes.buttonGuardar}
                                    >
                                        Validar
                                    </Button>
                                    {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </span>
                                </DialogActions>
                                :
                                <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                                     <span className={classes.wrapper}>
                                    <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  }
                                        variant="outlined" color="error" style={{   maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                                        className={classes.buttonRechazar}
                                    >
                                        Rechazar
                                    </Button>
                                    {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </span>
                                </DialogActions>
                            }
                            
                        </DialogContent>
                        </>
                        : usuId && parseInt(usuId) === 1 ? 
                        <>
                        <DialogContent >
                            {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
                            <Typography className={classes.margin} variant="subtitle2"> ¿Desea cambiar su Estatus?</Typography>
                           
                            <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', 
                                padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                                    <span className={classes.wrapper}>
                                <Button onClick={e => cambiarStatus(e, 1)} disabled={btnDeshabilitado? true : espera  }
                                    variant="outlined"  style={{ fontWeight:800,  maxWidth: '90px', maxHeight: '35px', minWidth: '90px', 
                                    minHeight: '35px', backgroundColor:"#FBBE06" ,color:"white"}}
                                    //className={classes.buttonRechazar}
                                >
                                    Pendiente
                                </Button>
                                {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </span>
                            </DialogActions>                                                   
                        </DialogContent>
                        </>
                        :null                        
                    }
                </Card>
            </Grid>
        </Grid>
    )
 
    return (
    <ModalInformativo
        setModalAbierto={setModalAbierto} modalAbierto={modalAbierto}
        titulo={titulo} nvoTitulo={seleccionado} tamanio={seleccionado.StatusId === 1 ? '' : "sm"}
        //subtitulo={"Sección " + seleccionado.Secc}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso}
    >
        {/* cardPerfil1(auxEstilo) */}
        {cardPerfil3}
    </ModalInformativo>
    )
}