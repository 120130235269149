import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,
        //Autocomplete,Polyline,Polygon,
         Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "500px",
  width: "100%"//"900px"
}

const center = {
  lat: 20.091146,  lng:  -98.762333
  //19.592062391482873,-98.91206954361832
}

const MapaConsultaMarcador = ({Seleccionado,tamanio}) => {
  //const [gps, setGps] = useState([])
  //const [rutaPol, setRutaPol] = useState([]);
  //const [autocomplete, setAutocomplete] = useState([]);
  const [auxMarcador, setAuxMarcador] = useState(center);
  //let autocomplete = [];
  let arreRutas=[];     
  useEffect(()=>
  {   
   //console.log(Seleccionado);

    let aux1=Seleccionado.Lat;            
    aux1=parseFloat(aux1)
    let aux2=Seleccionado.Lon;            
    aux2=parseFloat(aux2)        
    arreRutas={ lat : aux1, lng :aux2 }
    setAuxMarcador(arreRutas)
    //setCambiaMarcador(true)      

  },[Seleccionado]);

  /* const onLoad =(respuesta)=> {  
    setAutocomplete(respuesta);  
  } */

  /* const onPlaceChanged =() =>{ //auto complete para traer las posibles respuestas
    if ( autocomplete !== null||autocomplete.length>0  ) {
      console.log(autocomplete.getPlace())
      console.log(`location[${JSON.stringify(autocomplete.getPlace().geometry.location)}]`);
      //console.log(`location[${JSON.stringify(autocomplete.getPlace().formatted_address)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      setPromDir(autocomplete.getPlace().formatted_address)
      setGps(autocomplete.getPlace().geometry.location);        
      setPromGPS(autocomplete.getPlace().geometry.location);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  } */
  
  const image = { url: "marcador.svg"};
  //const image2 = { url:"marcador_seleccionado.svg"  };

  return (    
  <LoadScript googleMapsApiKey="AIzaSyBCY3rXFXjsPGx_X87eXMkgQklkrtfYmCQ" language="spanish" libraries={["places"]}> 
  <GoogleMap
    id="searchbox-example" center={auxMarcador}
    mapContainerStyle={tamanio ? tamanio:mapContainerStyle} zoom={14}        
  >         
    
    <Marker
      title={'Ninguno'} //onLoad={onLoad} 
      position={ auxMarcador} icon= {image}                   
    />
  </GoogleMap>
  </LoadScript>  
  );
}
 
export default MapaConsultaMarcador;