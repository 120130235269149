import React from 'react'
import {AuthContext} from '../context/AuthContext'
import {makeStyles,Hidden,CssBaseline} from '@material-ui/core'
import Cajon from './Cajon2'
import {authUser} from "../funciones/AuthUser";
import Navbar from './Navbar'
import {useLocation,useHistory} from "react-router-dom";
import {GeneralesContext} from '../context/GeneralesContext'
const estilos = makeStyles(theme=>({
    miroot:{
        display:'flex'
    },
    eltoolbar:theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
      },

}))
const Contenedor = (propiedades) => {
    const misclases = estilos()
    let location = useLocation();
    const [abrir,setAbrir]=React.useState(false);
    //const {usuario}=React.useContext(AuthContext)
    const {usuario,guardarUsuario}=React.useContext(AuthContext)    
    const {guardarGenerales}=React.useContext(GeneralesContext);  
    //const autenticado = authUser();
    const funcionAbrir=()=>
    {
        setAbrir(!abrir);
    }
    React.useEffect(()=>{
        const autenticado = authUser();
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');        
        if (autenticado && usu !== null) 
        {   
            //console.log(location.pathname);            
            guardarUsuario( { Nombre : usu, nivel : nvl } );   
         
         //   history.push("/worklist")
        }
        else
        {
            guardarUsuario([]);   
            guardarGenerales([])    
            propiedades.setColorTema(false) 
        }
        
    },[]);

    const cargar=()=>{
      
    }
    return (
        <div className={misclases.miroot}>
            <CssBaseline />
            {usuario.length !== 0  ?
                <Navbar funcionAbrir={funcionAbrir} usuario={usuario}
                colorTema={propiedades.colorTema} setColorTema={propiedades.setColorTema}
                />
                : null
            }
            {usuario.length !== 0 && abrir ? ( 
                
                <span>
                   
                    {/* <Hidden xsDown>
                        <Cajon  variant="permanent"
                            open={true} usuario={usuario}
                        />    
                    </Hidden>
                    <Hidden smUp>
                        <Cajon variant="temporary" usuario={usuario}
                            open={abrir} onClose={funcionAbrir}
                        />    
                    </Hidden> */}
                    <Hidden >
                        <Cajon variant="permanent" usuario={usuario}
                            open={abrir}  funcionAbrir={funcionAbrir} 
                        />    
                    </Hidden>
                </span>
            ):null}
            <div className={misclases.content}>
                <div className={misclases.eltoolbar}></div>
               
                <video controls="controls" hidden autoplay={true} >
  <source src="NotifTbd.mp3" type="audio/mp3"  />
</video>
  
{/* <iframe  allow="autoplay 'src' NotifTbd.mp3" autoplay={true} src="NotifTbd.mp3"  /> */}
                {
                    propiedades.children
                }
                
            </div>
        </div>
    )
}

export default Contenedor
