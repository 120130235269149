import React,{useState,useEffect,useContext} from 'react';
import MapaPanelSeccion from './MapaSeccion';
import MapaPanelMunicipio from './MapaMunicipio';
import { useHistory } from "react-router-dom";
import {authUser} from "../funciones/AuthUser";
import {AuthContext} from '../context/AuthContext'
//import {GeneralesContext} from '../context/GeneralesContext'
import {Box, Breadcrumbs,Typography,FormControl,InputLabel,Select,MenuItem} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import MapaPanelEstado from './MapaEdo';
const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    tabs:{
        padding:0,
        margin:0,
        minWidth:'150px',
        maxWidth:'150px',
        height:'30px',
        minHeight:'30px',
        color:'black'
    },
    contenedor:{
       marginTop:'1em',
       marginBottom:'1em',
       height:'30px',
       minHeight:'30px',
    }, 
    textoSelect: {
        '&:hover': {
          color:'#9ccdfa',
        },
        fontSize:1.25+'rem'
    },
  });
export default function MapaInicio (){
    const classes = useStyles();
    const history = useHistory();
    const source = axios.CancelToken.source();
    const nivelUsuario =localStorage.getItem('Tipo') ;
    const UsuId = localStorage.getItem('UsuId');
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);     
    const [espera,setEspera] = useState(false);  
    const [opcion,setOpcion] = useState(2)
    const [listEdo,setListEdo] = useState([])
    const [listMun,setListMun] = useState([])
    const [estadoId,setEstadoId] = useState(process.env.REACT_APP_EDO)
    const [listMapProm,setListMapProm] = useState([])
    const [munId,setMunId] = useState("")
    const [seccId,setSeccId] = useState(0)
    const [munSelect,setMunSelect] = useState([])
    useEffect(()=>{ 
        const llamadaListEdo = () => {
            let data = qs.stringify({
                usuario: UsuId,  idDispositivo: auxDispV,
            });
            let url = "lugar-estado";
    
            function respuesta(auxiliar) {
                if (auxiliar[0].Id != -1) {
                    llamaListMun(estadoId)
                    return setListEdo(auxiliar)
                }
            }    
            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }

        const usu= localStorage.getItem('UsuarioNom');        
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push('/');
            guardarUsuario([]);  
        }
        else
        {   if (usuario.length===0) 
            { guardarUsuario({Nombre:usu,nivel:nivelUsuario});                                                         
            }   
            llamadaListEdo()    
            guardarGenerales({...generales, Titulo: 'Mapa'})     
        }  

        return ()=> {
            source.cancel();
        }  

    },[]); 
    
    const llamaListMun = (auxEdo) => {
        setListMun([])
        let data = qs.stringify({
            usuario: UsuId, estado:auxEdo, idDispositivo: auxDispV,
        });
        let url = "lugar-municipio";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListMun(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }   

    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();        
        setOpcion(auxValor)
    }
    
    const menu=(
        <Box display="flex" justifyContent="center">
        <Breadcrumbs separator="-" aria-label="breadcrumb"  >
          {/*   <Typography variant="h5" color={opcion === 1 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,1)}
            >
                Estado
            </Typography> */}
            <Typography variant="h5" color={opcion === 3 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,3)}
            >
                Alcaldías
            </Typography>
            <Typography variant="h5" color={opcion === 2 ? "secondary":'textPrimary'} 
                className={classes.textoSelect} onClick={e=>selectOpcion(e,2)}
            >
                Sección
            </Typography>                                                                       
        </Breadcrumbs>
        </Box>
    )

    const onChange=(e)=>{    
        if (e!== null && e!== undefined) 
        { 
            setEstadoId( e.target.value )    
            llamaListMun(e.target.value)              
        }
        else
        {   setEstadoId("")           
        }        
        setMunId("")   
    }  

    const onChangeMun=(e)=>{    
        if (e!== null && e!== undefined) 
        {   let aux=listMun.filter((filtro)=>filtro.Id===parseInt(e.target.value) )
            //console.log(aux[0]);
            setMunSelect(aux[0])
            setMunId( e.target.value )                            
        }
        else
        {   setMunId("")           
        }        
    }  

    const listaEdo= listEdo.length!==0 ?                
        <Box display={"flex"}  >
            <FormControl size='small'  style={{ minWidth:8.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="Estado-label">
                    Estado
                </InputLabel>
                <Select  size='small' variant="outlined" disabled
                    label="Estado" id="Estado-select2" name="estadoId"
                    value={estadoId} onChange={onChange}
                >
                    {listEdo.map(( edo ) => {                        
                        return(
                        <MenuItem size='small' value={edo.Id} key={edo.Id}>
                            <em>{edo.Nom }</em>
                        </MenuItem>
                        )
                    } )}                    
                </Select>
            </FormControl>
        </Box>
        :   null
         
    const listaMun= listMun.length!==0 ?                
        <Box display={"flex"}  ml={1}>
            <FormControl size='small'  style={{ minWidth:8.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="muni-label">
                    Alcaldía
                </InputLabel>
                <Select  size='small' variant="outlined" 
                    label="Alcaldía" id="muni-select2" name="munId"
                    value={munId} onChange={onChangeMun}
                >
                    {listMun.map(( edo ) => {                        
                        return(
                        <MenuItem size='small' value={edo.Id} key={edo.Id}>
                            <em>{edo.Nom }</em>
                        </MenuItem>
                        )
                    } )}                    
                </Select>
            </FormControl>
        </Box>
        :   null

    function mostrarCont(){
        var cont =<div></div>
        switch(opcion){
            case 1:
                cont = <MapaPanelEstado estadoId={estadoId} />
                break;
            case 3:
                cont = munId !== "" ?<MapaPanelMunicipio estadoId={estadoId} 
                    munId={munId} munSelect={ munSelect}/>:null
                break;
            case 2:
                cont =  <MapaPanelSeccion  estadoId={estadoId}  
                    munId={munId} munSelect={ munSelect} /> 
                break;
            default:
                cont=<div></div>
                 break; 
        }
        return cont;
    }

     const validaCont = opcion>-1 ? mostrarCont() :null;
     const contenido = menu;

    return(
    <Box display="flex" flexDirection="column" style={{justifyContent:'center'}}>
        <Box style={{alignContent:'center'}}>
            <Box display="flex" row mt={.8} mb={.5}>
                {listaEdo}
                {listaMun}
            </Box>            
            {contenido}
        </Box>
        {validaCont}
    </Box>
    )
}
 