import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '36rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'.5rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '35rem',
      minHeight: '25rem',
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '35rem',
        maxHeight: '25rem',
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[                    
    { id:1, titulo:"Municipio", alinear:'center' },     
    { id:5, titulo:"Barda", alinear:'center' },      
    { id:6, titulo:"Lona", alinear:'center' },      
    { id:7, titulo:"Espectacular", alinear:'center' },      
   /*{ id:2, titulo:"Pendiente", alinear:'center'},      
    { id:3, titulo:"Valido", alinear:'center' },      
    { id:4, titulo:"Rechazado", alinear:'center' },   */    
  ];

const alturaCont = window.innerHeight<937 ? window.innerHeight-250 : window.innerHeight-270

export default function TablaMun({listAvMun,registro, setRegistro,registroEdo,listAvEdo,tipoTabla}){    
  const classes = useStyles();    
  const [promotor, setPromotor] = useState({nombre:""})
  const [seleccion, setSeleccion] = useState(null)
  const [datos,setDatos] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);     
  const auxCol=process.env.REACT_APP_Fondo_Color
  const {nombre} = promotor;    
  useEffect(()=>{    
    //console.log(listAvEdo);
    if (listAvEdo.length!==0 &&listAvEdo[0].avc_gral&& listAvEdo[0].avc_gral[0].EdoId!==0) 
    {    
      let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0, seccTot:0, otro:0}
      listAvEdo[0].avc_gral.forEach(element => {
        aux={ totHom:aux.totHom +element.Barda,
          totMuj: aux.totMuj +element.Lona,
          GenX:aux.GenX +element.Esp,
          otro:aux.otro +element.Otro,
          avGen:aux.avGen +element.Avc,
          MetaGen:aux.MetaGen +element.Meta,
          seccTot:aux.seccTot + 1,
        }
      });
      setDatos(listAvEdo[0].avc_gral)  
      setTotalGen(aux)
    }else
    {
      setDatos([])
    }

    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAvMun,tipoTabla])          

  const onChangeTable =(regis)=>{        
    setRegistro(regis)  
  }

  function filtro(){        
    let auxCadena=nombre.trim();       
    let info=[];                        
    let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
    info=aux 
    let auxNum=0;
      if (aux!==null||aux!=='') 
      {
      aux.forEach((mun)=>
      { if (mun.nombreReg!=='PUEBLA')
        {   
          auxNum++;
        }                
      })
    }         
    return TablaEstr(info);
  }


  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<25) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<50) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<75) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center"}}>            
      {`${status.toFixed(2)}%`}
      <FiberManualRecordIcon size="small" style={{color:stdColor,}}/>      
    </Box>)
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >     
         { totalGen.length!==0 ?
         <TableRow>
          <CelTitl index={1} titulo={<span>Municipio <br/> <strong>{totalGen.seccTot}</strong></span> } aline={"center"} />         
          <CelTitl index={2} titulo={<span>Meta <br/> <strong>{totalGen.MetaGen}</strong></span> } aline={"center"} />   
          <CelTitl index={3} titulo={<span>Avance <br/><strong>{totalGen.avGen}</strong></span>  } aline={"center"} />                         
          <CelTitl index={4} titulo={"" } aline={"center"} />         
          <CelTitl index={5} titulo={<span>Barda <br/><strong>{totalGen.totHom}</strong></span>} aline={"center"} />         
          <CelTitl index={6} titulo={<span>Lona <br/><strong>{totalGen.totMuj}</strong></span>} aline={"center"} />         
          <CelTitl index={7} titulo={<span>Espectacular <br/> <strong>{totalGen.GenX}</strong></span>} aline={"center"} />        
          <CelTitl index={8} titulo={<span>Otro <br/> <strong>{totalGen.otro}</strong></span>} aline={"center"} />                             
          </TableRow>
          :null }                   
        </TableHead>  
        <TableBody>  
        {auxDatos.map((elemnto,index) => {  
          const auxVal=registro?.MunId === elemnto.MunId ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index+1} onClick={ () => onChangeTable(elemnto) }  
          >            
            {celda("left", {width:"4rem"}, elemnto.MunNom ,index+1) }            
            {celda("center", {width:"4rem"}, elemnto.Meta ,index+2) }
            {celda("right", {width:"4rem"}, elemnto.Avc ,index+3) }
            {celda("right", {width:"6rem"}, colorStatus(elemnto.Porcent),index+4) }
            {celda("right", {width:"4rem"}, elemnto.Barda,index+5) }
            {celda("right", {width:"4rem"}, elemnto.Lona,index+6) }
            {celda("right", {width:"4rem",paddingRight:".3rem"}, elemnto.Esp,index+7) }     
            {celda("right", {width:"4rem",paddingRight:".3rem"}, elemnto.Otro,index+8) }     
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
  const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
  const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}>
                                    <Typography variant="h5" component="h5">No contiene datos</Typography>
                                    </Box>)                                                                                                           
                                
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '27rem',marginLeft:"1rem"}}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-180}}>                        
      {tipoTabla ===2 ?<Typography variant="body1" component="body1">{registroEdo.Num}</Typography> :null }
      {llamada}   
    </Card>

  </Box>                    
  );
}